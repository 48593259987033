// Font Weight
.do3-fw900 {
  font-weight: 900 !important;
}
.do3-fw800 {
  font-weight: 800 !important;
}
.do3-fw600 {
  font-weight: 600 !important;
}

// Font Size
.do3-1rem {
  font-size: 1rem;
}
.do3-1-5rem {
  font-size: 1.5rem;
  line-height: 1.2;
}

// Color Text
.do3-blue-text {
  color: #2F2F2F;
}
.do3-lightblue-text {
  color: #FF3D77;
}
.do3-gray-text {
  color: #979797 !important;
}
