// buttons
div,
button {
  border: none;
  background: transparent;
  &.do3-primary-btn {
    width: 100%;
    color: #fff;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    margin-bottom: 10px;
    transition: all 1s;
    font-size: 14px;
  }
  &.do3-secondary-btn {
    width: 100%;
    border-radius: 25px;
    padding: 10px;
  }
  &.blue {
    cursor: pointer;
    background-color: #2F2F2F;
  }
  &.gray {
    background-color: #ccc;
  }
  &.light-blue {
    background-color: #FF3D77;
  }
}
.do3-back-btn {
  display: flex;
  align-items: center;
  color: #979797;
  font-size: 18px;
  font-weight: 600;
  mat-icon {
    height: 25px;
    width: 25px;
    font-size: 25px;
    color: #707070;
    margin-right: 8px;
  }
}
